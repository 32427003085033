import {Component, OnInit} from '@angular/core';
import { AbstractControl, FormControl, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators, ValidationErrors } from '@angular/forms';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Guess} from '../model/guess.model';
import {HttpClient} from '@angular/common/http';
import {Message, MessageService} from 'primeng/api';
import * as Crypto from 'crypto-js';
import {TranslatePipe} from '@ngx-translate/core';
import {UtilsService} from '../services/utils.service';
import {NotificationService, NotificationType} from '../services/notification.service';
import {FormValidatorService} from '../services/form-validator.service';
import {AgencyAdminService} from '../services/agency-admin.service';

import {WindowRefService} from "../services/window-ref.service";
import {ServiceTypes} from "../model/service-types";
import * as _ from 'lodash';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import {MembershipService} from '../services/membership.service';

import { IDropdownSettings, NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss'],
	standalone: true,
	providers: [NotificationService, MessageService, UtilsService, TranslatePipe, AgencyAdminService, WindowRefService, FormValidatorService, MembershipService],
    imports: [ ReactiveFormsModule, NgMultiSelectDropDownModule ],
})

export class SignUpComponent implements OnInit {
  guessForm: UntypedFormGroup;
  showPwd = false;
  readOnly: Boolean = false;
  listOfPhoneCode: any = [];
  guess: Guess = new Guess();
  msgs: Message[] = [];
  private _jsonPhoneCodeURL = 'assets/country-dial-codes.json';
  dateOfBirth: any;
  maxDate = new Date();
  showPolicy = false;
  genders: Array<any> = [];
  accountTypeList: Array<any> = [];
  serviceTypeList: Array<any> = [];
  servicePlanList: Array<any> = [];
  paymentCard = null;
  paymentMethodData: any;
  zipCode = new FormControl('', [
    Validators.required,
    Validators.pattern(/^\d{1,5}$/)
  ]);
  isLoading = false;
  services: ServiceTypes = new ServiceTypes();
  options: any[];
  selectedServices: any[];
  placeholder = 'Login Type';
  required = false;
  inputControl = new UntypedFormControl(null);
 dropdownSettings: IDropdownSettings = {};
 show = false;
  constructor(
    private http: HttpClient,
    private formValidator: FormValidatorService,
    private notificationService: NotificationService,
    private utilsService: UtilsService,
    private router: Router,
    private translatePipe: TranslatePipe,
    private agencyAdminService: AgencyAdminService,
    private membershipService: MembershipService,
  ) {
  }

  ngOnInit() {
    this.genders = [{label: 'Male', value: 'MALE'}, {label: 'Female', value: 'FEMALE'}];
    this.accountTypeList = [{label: 'Guest', value: 'Guest'}, {label: 'Merchant', value: 'Merchant'}];
    this.serviceTypeList = [{label: 'OperrTel', value: 'OperrTel'}];
    this.servicePlanList = [
      {label: '$188/month - up to 5,000 times send', value: '$188/month - up to 5,000 times send'},
      {label: '$348/month - up to 10,000 times send', value: '$348/month - up to 10,000 times send'},
      {label: '$748/month - up to 25,000  times send', value: '$748/month - up to 25,000  times send'},
      {label: '$1248/month - up to 50,000 times send', value: '$1248/month - up to 50,000 times send'}
    ];
    this.getPhoneCode().subscribe(response => {
      this.listOfPhoneCode = [];
      const phoneCodes = response.countries;
      phoneCodes.forEach(item => {
        const data = {label: item.name + ' (' + item.code + ')', value: item.code};
        this.listOfPhoneCode.push(data);
      });
    });
    // init form group
    this.guessForm = new UntypedFormGroup({
      first_name: new UntypedFormControl({
        value: '',
        disabled: this.readOnly
      }, [Validators.required, this.noWhitespaceValidator]),
      last_name: new UntypedFormControl({
        value: '',
        disabled: this.readOnly
      }, [Validators.required, this.noWhitespaceValidator]),
      email: new UntypedFormControl({value: '', disabled: this.readOnly}, [Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')], [this.validateEmailNotTaken.bind(this), this.validateEmailAdmin.bind(this)]),
      username: new UntypedFormControl({value: '', disabled: this.readOnly},
        [Validators.required], [this.validateUserNameNotTaken.bind(this),
          this.validateUserNameNotTakenAdmin.bind(this)]),
      password: new UntypedFormControl({
        value: '',
        disabled: this.readOnly
      }, [Validators.required, this.noWhitespaceValidator, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})/)]),
      phone_code: new UntypedFormControl({value: '', disabled: this.readOnly}, [Validators.required]),
      phone: new UntypedFormControl({
        value: '',
        disabled: this.readOnly
      }, [Validators.required, this.noWhitespaceValidator, this.customPhoneValidate]),
      date_of_birth: new UntypedFormControl({value: new Date(), disabled: this.readOnly}, Validators.required),
      gender: new UntypedFormControl({value: '', disabled: this.readOnly}, Validators.required),
      referenceBy: new UntypedFormControl({value: '', disabled: this.readOnly}),
      accountType: new UntypedFormControl({value: '', disabled: this.readOnly}, Validators.required),
      serviceType: new UntypedFormControl({value: '', disabled: this.readOnly}),
      serviceTypes: new UntypedFormControl({value: '', disabled: this.readOnly}),
      servicePlan: new UntypedFormControl({value: '', disabled: this.readOnly}),
      addressOne: new UntypedFormControl({value: '', disabled: this.readOnly}),
      city: new UntypedFormControl({value: '', disabled: this.readOnly}),
      state: new UntypedFormControl({value: '', disabled: this.readOnly}),
      zipcode: new UntypedFormControl({value: '', disabled: this.readOnly}),
      paymentGroup: new UntypedFormControl({value: '', disabled: this.readOnly}),
    });
    this.guessForm.get('gender').setValue(null);
    this.guessForm.get('accountType').setValue(null);
    this.dateOfBirth = new Date('2000-01-01');
    this.placeholder = this.required ? `${this.placeholder} *` : this.placeholder;
    this.options = _.map(this.services.serviceType, (s: { id: any; name: any; }) => {
      const option = {value: s.id, label: s.name};
      return option;
    });
    this.selectedServices = ["OperrTel"];
/*    this.dropdownSettings = {
      singleSelection: false,
      defaultOpen: false,
      idField: "value",
      textField: "label",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3
    };
*/ 
}

  onSelectionChange(event: any) {
    // if (this.onChange) {
    //   this.onChange(event.value);
    // }
  }

  removeValidation() {
    this.guessForm.controls['first_name'].setValidators([]);
    this.guessForm.controls['first_name'].updateValueAndValidity();

    this.guessForm.controls['last_name'].setValidators([]);
    this.guessForm.controls['last_name'].updateValueAndValidity();

    this.guessForm.controls['email'].setValidators([]);
    this.guessForm.controls['email'].updateValueAndValidity();

    this.guessForm.controls['username'].setValidators([]);
    this.guessForm.controls['username'].updateValueAndValidity();

    this.guessForm.controls['password'].setValidators([]);
    this.guessForm.controls['password'].updateValueAndValidity();

	this.guessForm.controls['password2'].setValidators([]);
    this.guessForm.controls['password2'].updateValueAndValidity();

    this.guessForm.controls['phone_code'].setValidators([]);
    this.guessForm.controls['phone_code'].updateValueAndValidity();

    this.guessForm.controls['date_of_birth'].setValidators([]);
    this.guessForm.controls['date_of_birth'].updateValueAndValidity();

    this.guessForm.controls['gender'].setValidators([]);
    this.guessForm.controls['gender'].updateValueAndValidity();

    this.guessForm.controls['accountType'].setValidators([]);
    this.guessForm.controls['accountType'].updateValueAndValidity();
  }

  createGuess(dataForm: any) {
    this.guessForm.patchValue({
      serviceType: 'OperrTel',
      gender: 'MALE',
      accountType: 'Merchant',
    });
    if (!this.formValidator.validateForm(this.guessForm, dataForm)) {
      const invalid = [];
      for (const name in this.guessForm.controls) {
        if (this.guessForm.controls[name].invalid) {
          invalid.push(name);
        }
      }
      this.notificationService.open({
        type: NotificationType.ERROR,
        title: this.translatePipe.transform('InvalidData'),
        body: this.translatePipe.transform('PleaseEnterRequiredFields'),
      });
      return;
    }

    if (this.formValidator.validateForm(this.guessForm, dataForm)) {
      this.removeValidation();
      this.dateOfBirth.setMinutes(this.dateOfBirth.getMinutes() + this.dateOfBirth.getTimezoneOffset());
      this.guess.dateOfBirth = this.dateOfBirth;
      // this.guess.payload = this.paymentMethodData.formValues;
      // this.guess.paymentCardType = this.paymentMethodData.paymentCardType;
      // this.guess.cardDataStr = this.paymentMethodData.cardDataStr;
      this.guess.fakeCode = this.utilsService.generateFirst7Character() + this.guess.password;
      const payload = {
        ...this.guess,
        passwordMD5: Crypto.MD5(this.guess.password).toString(),
        accountType: 'Merchant',
        addressOne: '30-50 Whitestone Expy',
        city: 'New York',
        gender: 'MALE',
        state: 'Queens',
        referenceBy: 'Danny',
        servicePlan: '$188/month - up to 5,000 times send',
        zipcode: '12345'
      };

      console.log('createGuess guess', payload);
      this.isLoading = true;
      this.agencyAdminService.createAdminAccount(payload).subscribe(res => {
        if (res) {
          const resObj: any = res;
          if (resObj.status === 'SUCCESS') {
            this.notificationService.open({
              type: NotificationType.SUCCESS,
              title: 'Success',
              body: 'The account sign-up successfully. Please check your email for more details.',
            });
            localStorage.removeItem('_register_guest');
            localStorage.setItem('_register_guest', 'true');
            setTimeout(() => this.router.navigate(['/home']), 2000);
          } else {
            this.notificationService.open({
              type: NotificationType.ERROR,
              title: 'Error',
              body: resObj.message,
            });
          }
          this.isLoading = false;
        }
      });
    }
  }

  public getPhoneCode(): Observable<any> {
    return this.http.get(this._jsonPhoneCodeURL);
  }


  backToLogin() {
    this.router.navigate(['/', 'login']);
  }

  noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : {'whitespace': true};
  }

  customPhoneValidate(control: AbstractControl) {
    const value = control.value;
    if (value && !!value.replace(/\s/g, '')) {
      const isValid = value.match(/(^[^a-zA-Z0-9() ]{1,}| [^a-zA-Z0-9() ]{2,})/g);
      if (!!isValid) {
        return {
          'custom': true
        };
      }
    }
    return null;
  }

  passwordValidator(control: AbstractControl) {
    const value = control.value;
    if (value && !!value.replace(/\s/g, '')) {
      const isValid = value.match(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{10,}$/);
      if (!!isValid) {
        return {
          'custom': true
        };
      }
    }
    return null;
  }

  showPassword() {
    this.showPwd = !this.showPwd;
  }

  reset(event: any) {
    console.log('reset event: ', event);
  }

  validateEmailAdmin(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.agencyAdminService.isEmailAvail(control.value).pipe(
      map(isTaken => {
        return (isTaken ? { userTaken: true } : null);
      }),
      catchError(() => null)
    );
  }

  validateEmailNotTaken(control: AbstractControl): Observable<ValidationErrors | null> {
    return this.membershipService.isEmailAvail(control.value).pipe(
      map(isTaken => {
        return (isTaken ? { userTaken: true } : null);
      }),
      catchError(() => null)
    );
  }

  validateUserNameNotTaken(control: AbstractControl): Observable<ValidationErrors | null> {
    if (_.size(control.value) < 8) {
      return of({ minLength: true });
    }
    return this.membershipService.isAccountNameAvail(control.value).pipe(
      map(isTaken => {
        return (isTaken ? { userTaken: true } : null);
      }),
      catchError(() => null)
    );
  }

  validateUserNameNotTakenAdmin(control: AbstractControl): Observable<ValidationErrors | null> {
    if (_.size(control.value) < 8) {
      return of({ minLength: true });
    }
    return this.agencyAdminService.isAccountNameAvailWithId(control.value, null).pipe(
      map(isTaken => {
        return (isTaken ? { userTaken: true } : null);
      }),
      catchError(() => null)
    );
  }

  onCheckboxChange(event: any, value: string) {
  if (event.target.checked) {
    // If checked, add the value to the selected services array
    if (!this.selectedServices.includes(value)) {
      this.selectedServices.push(value);
    }
  } else {
    // If unchecked, remove the value from the selected services array
    this.selectedServices = this.selectedServices.filter(service => service !== value);
  }
}

}
