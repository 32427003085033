import {Component, OnInit} from '@angular/core';
import {map, tap} from 'rxjs/operators';
import {AsyncPipe, CommonModule} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {Router, RouterModule} from '@angular/router';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [AsyncPipe, CommonModule, FormsModule, RouterModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent implements OnInit {
  contentList: any[] = [];
  isMonthly = true;

  constructor(protected _http: HttpClient, private router: Router,) {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  }

  ngOnInit() {
    this.getAllPlan().subscribe(res => {
      const resObj: any = res;
      if (resObj.status === 'SUCCESS') {
        const data = resObj.data;
        for (const con of data) {
          this.contentList.push(con);
        }
      } else {
        this.contentList = [];
      }
    });
  }

  getAllPlan() {
    return this._http.get(`${environment.v2_server_backend}/operation-service/api/v2/operation/plan/get-all-plan`).pipe(map(res => res));
  }

  checkout() {
    this.router.navigate(['/checkout']);
  }
}
