import {Component} from '@angular/core';
import {RouterLink, RouterOutlet} from '@angular/router';
import {environment} from "../environments/environment";
import {TranslateService} from "@ngx-translate/core";
import {CookieService} from "ngx-cookie-service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterLink, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  loginUrl = environment.login_url;
  signUpUrl = environment.sign_up_url;

  constructor(private translate: TranslateService, private cookieService: CookieService) {
    let currentLang = 'en';
    if (this.cookieService.get('_currentLang')) {
      currentLang = this.cookieService.get('_currentLang');
    }
    translate.setDefaultLang(currentLang);
    translate.use(currentLang);

    this.cookieService.set('_currentLang', currentLang);
    this.translate.use(this.translate.currentLang ? this.translate.currentLang : 'en');
  }
}
