<div class="container-fluid px-0 shadow d-flex align-items-center bg-darkblue pt-5" id="headerArea">
	<div class="container-lg m-auto text-center">
	  <div class="row align-items-center justify-content-center">
		<div class="col-lg-12 text-center px-5">
		  <div class="text-warning mb-4" id="headerSubtext">Election Campaign Software</div>
		  <div class="text-light" id="headerText">Detailed block-by-block voter data from walkers gives you insights into your voting constituency.</div>
		  <i class="fa-solid fa-angles-down text-warning fa-3x mt-5"></i>
		</div>
		<!--
			  <div class="col-lg-12 text-center">
				   <img src="assets/images/campaign-screens.png" style="width: 100%;" id="screens">
			   </div>
  
		 -->
	  </div>
	</div>
  </div>
  
  <div class="container-fluid shadow pb-5" style="overflow: visible;margin-top: -367px;" >
	<div class="col-lg-12 text-center">
	  <img src="../../assets/images/campaign-screens.png" style="width: 80%;max-width: 1200px;" id="screens" alt="">
	</div>
	<div class="container-lg m-auto py-5">
	  <div class="row justify-content-between">
		<div class="col-lg-7">
		  <h1>Manage Political Campaigns</h1>
		  <p style="font-size: 18px;">In the fast-paced world of politics, staying organized, reaching voters effectively, and managing
			campaign resources efficiently are essential. Our Political Campaign Software is your comprehensive
			solution to streamline campaign operations, maximize voter engagement, and propel your political
			journey towards success. This overview will introduce you to the key features and benefits of this
			powerful software. Operrwork Political Campaign management utilizes geospatial analysis to identify
			areas with high concentrations of the candidate's target audience, allowing for strategic deployment
			of campaign resources and events.</p>
		  <div class="mt-5" style="font-size: 18px;">
			<div><i class="fa-solid fa-check text-success me-2"></i>Monitor walker progress for better voter outreach.</div>
			<div><i class="fa-solid fa-check text-success me-2"></i>Easily upload & maintain a centralized voter database.</div>
			<div><i class="fa-solid fa-check text-success me-2"></i>Analyze voter data to tailor your campaign strategies.</div>
			<div><i class="fa-solid fa-check text-success me-2"></i>Recruit, schedule, and communicate with campaign volunteers.</div>
			<div><i class="fa-solid fa-check text-success me-2"></i>Track volunteer activities and contributions.</div>
			<div><i class="fa-solid fa-check text-success me-2"></i>Send personalized emails & SMS media messages to voters.</div>
		  </div>
  
		</div>
		<div class="col-lg-4">
		  <div class="mt-5" style="font-size: 18px;">
			<div><i class="fa-solid fa-circle-check text-success me-2"></i>Import & Procces Voter Data</div>
			<div><i class="fa-solid fa-circle-check text-success me-2"></i>Promote Campaign w/ Bulk SMS</div>
			<div><i class="fa-solid fa-circle-check text-success me-2"></i>Manage Volunteers & Walkers</div>
			<div><i class="fa-solid fa-circle-check text-success me-2"></i>Create Zones for Walkers</div>
			<div><i class="fa-solid fa-circle-check text-success me-2"></i>Dispatch your Campaign Walkers</div>
			<div><i class="fa-solid fa-circle-check text-success me-2"></i>Create & Use Voter Questionnaires</div>
			<div><i class="fa-solid fa-circle-check text-success me-2"></i>Manage Campaign Workers</div>
			<div><i class="fa-solid fa-circle-check text-success me-2"></i>Daily HR Tools for Workers</div>
		  </div>
		</div>
	  </div>
	  <div class="row align-items-start mt-5">
		<div class="col-lg-12 text-center py-5">
		  <img src="../../assets/images/campaign-laptop.png" style="width: 100%;max-width: 1000px;">
		</div>
		<div class="col-lg-12 text-center">
		  <h1>Reach Out to Voters Directly</h1>
		</div>
		<div class="col-lg-4 p-4" style="font-size: 18px;">
		  In today's digital age, it's more crucial than ever to reach voters where they are. Our system makes
		  this effortless with tools designed for modern communication. Send tailored emails, SMS, and social
		  media messages that resonate with your voter base. By crafting messages that resonate, candidates can
		  effectively engage and inspire action among potential supporters.
		</div>
		<div class="col-lg-4 p-4" style="font-size: 18px;">
		  Communication and communication efficiency is at the heart of a successful campaign. Our software
		  anticipates this need, offering features like automated message scheduling and responses. This not only
		  saves valuable time but ensures that no communication opportunity is missed, keeping your political
		  campaign's positive momentum going.
		</div>
		<div class="col-lg-4 p-4" style="font-size: 18px;">
		  Our built-in SMS feature stands as a testament to our commitment to effective outreach. With this,
		  candidates can send personalized messages straight to potential voters' devices. Such direct channels of
		  communication often translate into higher engagement rates, mobilizing the target audience more
		  effectively, making sure to reach every voter.
		</div>
	  </div>
  
	</div>
  </div>
  
  
  
  <!--
  <div class="container-fluid shadow pb-5" style="overflow: hidden;" id="benefits">
	  <div class="container-lg m-auto py-5">
		  <div class="row align-items-start justify-content-between  my-5">
  
			  <div class="col-lg-6 text-start p-3">
  
				  <h1>Manage Political Campaigns</h1>
				  <p>In the fast-paced world of politics, staying organized, reaching voters effectively, and managing
					  campaign resources efficiently are essential. Our Political Campaign Software is your comprehensive
					  solution to streamline campaign operations, maximize voter engagement, and propel your political
					  journey towards success. This overview will introduce you to the key features and benefits of this
					  powerful software. Operrwork Political Campaign management utilizes geospatial analysis to identify
					  areas with high concentrations of the candidate's target audience, allowing for strategic deployment
					  of campaign resources and events.</p>
  
  
				  <h3 class="mt-5">Election Campaign Staff</h3>
				  <p>The system can help you manage your staff or campaign employees, track their working hours (predict
					  and track overtime hours), generate time-slips and more. Our punch-clock feature allows for on-site
					  or in the field punch features and even AI facial recognition. Our system will help with all your
					  recordkeeping and management needs. We are even about to launch payroll features as well, saving you
					  time and money in the HR department.
				  </p>
  
				  <h3 class="mt-5">Election Campaign Operations</h3>
				  <p>Compile specific constituent and voter information using the Voter Lists in the system. Create and
					  manage your campaign walkers. Assign working zones and monitor in real time where your walkers are
					  working in the zone. Store your potential voter records in the system and then use them to send out
					  campaign communications or mailers. Data visualizations let you display your voter data by zone,
					  using a graphical interface, quickly displaying your constituent demographics, needs &amp;
					  complaints in vivid charts and graphs. </p>
  
  
			  </div>
  
			  <div class="col-lg-4 col-sm-12">
				  <h3>For Campaign Trail</h3>
				  <ul>
					  <li>Create &amp; manage campaign walkers</li>
					  <li>Create work zones for campaign walkers</li>
					  <li>Election &amp; assembly district map zones</li>
					  <li>Map &amp; track walker positions &amp; progress</li>
					  <li>Collect &amp; store voter contact info</li>
					  <li>Manage &amp; use voter contact details</li>
					  <li>Send campaign messaging to contacts</li>
					  <li>Auto-call voters using Voter List</li>
				  </ul>
				  <br>
				  <h3 clss="mt-5">For Campaign Office</h3>
				  <ul>
					  <li>Create &amp; manage employee/staff</li>
					  <li>Track working hours (in-house &amp; field)</li>
					  <li>Other HR Tools (Overtime control, etc)</li>
					  <li>Track office &amp; campaign supplies</li>
					  <li>Generate purchase request records</li>
					  <li>Add &amp; Track vehicles, milage, expenses</li>
					  <li>Campaign equipment check-in/out</li>
					  <li>Crisp video conferencing tools</li>
				  </ul>
				  <img src="assets/images/campaign-tracking.png" class="mt-5" style="width: 95%;margin-bottom: -140px;">
			  </div>
		  </div>
	  </div>
  </div>
  
  
  
  
  <div class="container-fluid shadow">
	  <div class="container-lg m-auto py-5">
		  <div class="row align-items-start">
			  <div class="col-lg-12 text-center py-5">
				  <img src="assets/images/campaign-laptop.png" style="width: 100%;max-width: 1000px;">
			  </div>
			  <div class="col-lg-12 text-center">
				  <h1>Communication Tools</h1>
			  </div>
			  <div class="col-lg-4 p-4">
				  In today's digital age, it's more crucial than ever to reach voters where they are. Our system makes
				  this effortless with tools designed for modern communication. Send tailored emails, SMS, and social
				  media messages that resonate with your voter base. By crafting messages that resonate, candidates can
				  effectively engage and inspire action among potential supporters.
			  </div>
			  <div class="col-lg-4 p-4">
				  Communication and communication efficiency is at the heart of a successful campaign. Our software
				  anticipates this need, offering features like automated message scheduling and responses. This not only
				  saves valuable time but ensures that no communication opportunity is missed, keeping your political
				  campaign's positive momentum going.
			  </div>
			  <div class="col-lg-4 p-4">
				  Our built-in SMS feature stands as a testament to our commitment to effective outreach. With this,
				  candidates can send personalized messages straight to potential voters' devices. Such direct channels of
				  communication often translate into higher engagement rates, mobilizing the target audience more
				  effectively, making sure to reach every voter.
			  </div>
		  </div>
	  </div>
  </div>
  -->
  <div class="container-fluid bg-warning px-0 shadow d-flex align-items-center pb-5" id="features">
	<div class="container-lg m-auto text-start py-5">
	  <div class="ui-g mt-5">
		<div class="ui-g-12 text-center">
		  <div class="text-dark mb-5" style="font-size: 50px;">Manage, Analyze & Promote</div>
		</div>
	  </div>
	  <div class="row p-4 justify-content-between mb-5">
		<div class="col-lg-3 col-md-6 col-sm-12">
		  <h3>Voter Database</h3>
		  <p>Easily upload and maintain a centralized voter database. Analyze demographics and behaviors to tailor
			your campaign strategies.</p>
		</div>
		<div class="col-lg-3 col-md-6 col-sm-12">
		  <h3>Voter Outreach</h3>
		  <p>Upload voter files and assign them to campaign walkers. Monitor the progress of every walker,
			ensuring comprehensive voter outreach.</p>
		</div>
		<div class="col-lg-3 col-md-6 col-sm-12">
		  <h3>Text Messaging</h3>
		  <p>Send campaign updates, reach out for donations, etc. using our bulk messaging system which utilizes
			SMS messaging.</p>
		</div>
		<div class="col-lg-3 col-md-6 col-sm-12">
		  <h3>Coordination </h3>
		  <p>Recruit, schedule, and communicate with campaign volunteers. Track volunteer activities and
			contributions.</p>
		</div>
		<div class="col-lg-12 text-center pt-5">
		  <button class="btn btn-dark btn-lg">
			<svg class="svg-inline--fa fa-phone me-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
			  <path fill="currentColor" d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"></path>
			</svg><!-- <i class="fa-solid fa-phone me-2"></i> Font Awesome fontawesome.com -->Call Today: (929) 201-1899
		  </button>
		</div>
	  </div>
	</div>
  </div>
  
  
  <div class="container-fluid shadow pb-5" style="overflow: hidden;background: #f9f9f9 !important;" id="plan">
	<div class="container-lg m-auto py-5">
	  <div class="ui-g">
		<div class="ui-g-12 text-center">
		  <div class="text-center align-items-center py-4">
			<h1 *ngIf="isMonthly">Monthly Pricing</h1>
			<h1 *ngIf="!isMonthly">Annual Pricing</h1>
			<div class="form-check form-switch">
			  <span class="form-check-label me-2" for="flexSwitchCheckDefault">Annual </span>
			  <input class="form-check-input mx-2" [(ngModel)]="isMonthly" type="checkbox" role="switch" id="flexSwitchCheckDefault">
			  <span class="form-check-label" for="flexSwitchCheckDefault"> Monthly</span>
			</div>
		  </div>
		</div>
		<div class="d-flex flex-wrap justify-content-start">
		  <div *ngFor="let content of contentList" class="p-4 m-2 add-shadow bg-white text-center" id="planCard" style="width: 23%;min-width: 250px;">
			<h3 class="mb-0 mt-4 text-truncate" style="text-transform: capitalize;">{{content.packageName}}</h3>
			<div class="subtext" style="margin-bottom: 20px; font-size: 16px">{{content.packageInfo}}</div>
			<h5 class="m-0 mb-2">Included Features</h5>
			<div class="text-left" style="font-size: 14px;min-height: 150px;">
			<div *ngFor="let key of content.keyPoints" style="color:#737373"><i class="fa-solid fa-check text-success me-1"></i>{{key}}</div></div>
  
			<h4 *ngIf="isMonthly" class="mt-4">{{content.monthlyFee | currency : 'USD':'symbol':'1.2-2'}} mo</h4>
			<h4 *ngIf="!isMonthly" class="mt-4">{{content.annualFee | currency : 'USD':'symbol':'1.2-2'}} yr</h4>
  
			<div>
			  <a class="btn btn-primary my-4" routerLink="/checkout" (click)="checkout()">
				<i class="fa-solid fa-hand-point-right me-2"></i> Get Started <i class="fa-solid fa-chevron-right ms-2"></i>
			  </a>
			</div>
		  </div>
		</div>
		<div class="col-lg-12 col-sm-12 text-center pt-5">
		  <h1 class="m-0 my-5">Have questions? Call us!<br> (929) 201-1899</h1>
		</div>
	  </div>
	</div>
  </div>
  
  <div class="container-fluid bg-danger px-0 shadow d-flex align-items-center py-5" id="brochure">
	<div class="container-lg m-auto text-center py-5">
	  <div class="row p-5 justify-content-between mb-5">
		<div class="col-lg-12">
  
		</div>
		<div class="col-lg-4 text-start">
		  <h1 class="text-light">Benefits</h1>
		  <p class="text-light">Political Campaign Software empowers you to run a data-driven, efficient, and
			successful political campaign. By leveraging its advanced features, including voter file uploads and
			walker tracking, you can connect with voters, raise funds, and manage campaign resources
			effectively. Take the first step towards victory with the right tools at your disposal. </p>
		  <a class="btn btn-warning btn-lg mt-4" href="https://www.operrwork.com/">
			<svg class="svg-inline--fa fa-glasses me-2" aria-hidden="true" focusable="false" data-prefix="fas"
				 data-icon="glasses" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"
				 data-fa-i2svg="">
			  <path fill="currentColor"
					d="M118.6 80c-11.5 0-21.4 7.9-24 19.1L57 260.3c20.5-6.2 48.3-12.3 78.7-12.3c32.3 0 61.8 6.9 82.8 13.5c10.6 3.3 19.3 6.7 25.4 9.2c3.1 1.3 5.5 2.4 7.3 3.2c.9 .4 1.6 .7 2.1 1l.6 .3 .2 .1 .1 0 0 0 0 0s0 0-6.3 12.7h0l6.3-12.7c5.8 2.9 10.4 7.3 13.5 12.7h40.6c3.1-5.3 7.7-9.8 13.5-12.7l6.3 12.7h0c-6.3-12.7-6.3-12.7-6.3-12.7l0 0 0 0 .1 0 .2-.1 .6-.3c.5-.2 1.2-.6 2.1-1c1.8-.8 4.2-1.9 7.3-3.2c6.1-2.6 14.8-5.9 25.4-9.2c21-6.6 50.4-13.5 82.8-13.5c30.4 0 58.2 6.1 78.7 12.3L481.4 99.1c-2.6-11.2-12.6-19.1-24-19.1c-3.1 0-6.2 .6-9.2 1.8L416.9 94.3c-12.3 4.9-26.3-1.1-31.2-13.4s1.1-26.3 13.4-31.2l31.3-12.5c8.6-3.4 17.7-5.2 27-5.2c33.8 0 63.1 23.3 70.8 56.2l43.9 188c1.7 7.3 2.9 14.7 3.5 22.1c.3 1.9 .5 3.8 .5 5.7v6.7V352v16c0 61.9-50.1 112-112 112H419.7c-59.4 0-108.5-46.4-111.8-105.8L306.6 352H269.4l-1.2 22.2C264.9 433.6 215.8 480 156.3 480H112C50.1 480 0 429.9 0 368V352 310.7 304c0-1.9 .2-3.8 .5-5.7c.6-7.4 1.8-14.8 3.5-22.1l43.9-188C55.5 55.3 84.8 32 118.6 32c9.2 0 18.4 1.8 27 5.2l31.3 12.5c12.3 4.9 18.3 18.9 13.4 31.2s-18.9 18.3-31.2 13.4L127.8 81.8c-2.9-1.2-6-1.8-9.2-1.8zM64 325.4V368c0 26.5 21.5 48 48 48h44.3c25.5 0 46.5-19.9 47.9-45.3l2.5-45.6c-2.3-.8-4.9-1.7-7.5-2.5c-17.2-5.4-39.9-10.5-63.6-10.5c-23.7 0-46.2 5.1-63.2 10.5c-3.1 1-5.9 1.9-8.5 2.9zM512 368V325.4c-2.6-.9-5.5-1.9-8.5-2.9c-17-5.4-39.5-10.5-63.2-10.5c-23.7 0-46.4 5.1-63.6 10.5c-2.7 .8-5.2 1.7-7.5 2.5l2.5 45.6c1.4 25.4 22.5 45.3 47.9 45.3H464c26.5 0 48-21.5 48-48z"></path>
			</svg><!-- <i class="fa-solid fa-glasses me-2"></i> Font Awesome fontawesome.com --> Get Brochure
		  </a>
		</div>
		<div class="col-lg-6">
		  <img src="../../assets/images/campaign-phone-photo.png"
			   style="width: 85%;border: 15px solid #fff;border-bottom: 48px solid #ffff;box-shadow: 0px 11px 23px -13px;">
		</div>
	  </div>
	</div>
  </div>
  
  
  <div id="loom-companion-mv3" ext-id="liecbddmkiiihnedobmlmillhodjkdmb">
	<section id="shadow-host-companion"></section>
  </div>
  