import { Component } from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {PanelModule} from "primeng/panel";
import {DropdownModule} from "primeng/dropdown";
import {InputMaskModule} from "primeng/inputmask";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {MultiSelectModule} from "primeng/multiselect";
import {ToastModule} from "primeng/toast";

@Component({
  selector: 'app-checkout',
  standalone: true,
  imports: [TranslateModule, FormsModule, ReactiveFormsModule, HttpClientModule, ScrollingModule, CommonModule, RouterModule, PanelModule,
    DropdownModule,
    InputMaskModule,
    ProgressSpinnerModule,
    MultiSelectModule, ToastModule],
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.scss'
})
export class CheckoutComponent {
  constructor() {
    window.scrollTo(0,0);
  }
}
