import {Routes} from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {CheckoutComponent} from "./checkout/checkout.component";
import {SignUpComponent} from "./sign-up/sign-up.component";

export const routes: Routes = [
  { path: '', component: HomeComponent, },
  { path: 'home', component: HomeComponent, },
  {
    path: 'sign-up',
    component: SignUpComponent
  },
  {
    path: 'checkout',
    component: CheckoutComponent
  }
];
