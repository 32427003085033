import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AbstractService} from './abstract.service';
import {Constants} from '../const/constants';
import {AgencyAdmin} from '../model/agency-admin.model';

@Injectable()
export class AgencyAdminService extends AbstractService<AgencyAdmin> {

  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.ADMIN, '');
  }

  createAdminAccount(entity: any): Observable<any> {
    return this._http.post<any>(`${this.baseUrlV2}/create-admin-account`, entity).pipe(map(res => res));
  }

  isEmailAvail(email: string) {
    const URL = this.baseUrlV2 + '/email-exist';
    const option = {email: email};
    return this._http.post(URL, option);
  }

  isAccountNameAvailWithId(accountName: string, id: Number) {
    const URL = this.baseUrlV2 + '/isAccountNameAvail';
    const option = {accountName: accountName};
    return this._http.get(URL, {params: option});
  }

}
