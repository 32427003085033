import { Injectable } from '@angular/core';
import { Membership } from '../model/membership.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {Constants} from '../const/constants';
import {AbstractService} from './abstract.service';

@Injectable()
export class MembershipService extends AbstractService<Membership> {
  constructor(private http: HttpClient) {
    super(http, Constants.ROUTES.MEMBERSHIP, '');
  }

  createSignUp(entity: any): Observable<any> {
    const url = environment.v2_server_ip + '/membership/api/v2/guest';
    return this._http.post<any>(url, entity).pipe(map(res => res));
  }
  isAccountNameAvail(accountName: any) {
    const url = environment.v2_server_ip + '/membership/api/v2/guest/isAccountNameAvail';
    const option = { accountName: accountName };
    return this._http.get(url, { params: option });
  }
  isEmailAvail(email: string) {
    const URL = environment.v2_server_ip + '/membership/api/v2/guest/email-exist';
    const option = {email: email};
    return this._http.post(URL, option);
  }
}
