<div class="container-fluid px-0 shadow d-flex align-items-center bg-darkblue" id="headerArea">
	<div class="container-lg m-auto text-center">
		<div class="row align-items-center justify-content-start">
			<div class="col-lg-12 text-left px-5">
				<div class="text-light" id="headerText">Create Account</div>
			</div>
		</div>
	</div>
</div>
<div class="container-fluid px-0 shadow d-flex align-items-center pb-5">
	<div class="container-lg m-auto">
		<div class="ui-g mt-5">
			<div class="ui-g-12">
				<h1 class="m-0 ms-2 mt-4">Primary Contact</h1>
				<div class="ms-2 mb-4">The person you would like us to contact in case of issues.</div>
			</div>
		</div>
	 	<div class="card p-0">
			<div class="card-header">
				<span><i class="fa-solid fa-circle-plus text-primary me-2"></i>Contact Info</span>
			</div>
			<form [formGroup]="guessForm" #guessDataForm [name]="guessDataForm">
				<div class="ui-g p-4">
					<div *ngIf="guessForm.get('email').errors?.userTaken || guessForm.get('username').errors?.userTaken || guessForm.controls['password'].errors?.pattern && guessForm.controls['password'].dirty" class="ui-g-12">
						<div *ngIf="guessForm.get('email').errors?.userTaken" class="alert alert-danger alert-dismissible fade show" role="alert">
							<span class=""><strong><i class="fa-solid fa-triangle-exclamation me-2"></i>{{'Alert'}}: </strong> {{'EmailExistingInSystem'}}</span>
							<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
						</div>
						<div *ngIf="guessForm.get('username').errors?.userTaken" class="alert alert-danger alert-dismissible fade show" role="alert">
							<span class=""><strong><i class="fa-solid fa-triangle-exclamation me-2"></i>{{'Alert'}}: </strong> {{'validate.thisFieldNotAvailable'}}</span>
							<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
						</div>
						<div *ngIf="guessForm.controls['password'].errors?.pattern && guessForm.controls['password'].dirty" class="alert alert-warning alert-dismissible fade show" role="alert">
							<span class=""><strong><i class="fa-solid fa-triangle-exclamation me-2"></i>{{'Alert'}}: </strong> {{'password_pattern_required'}}</span>
							<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
						</div>
					</div>
					<div class="ui-g-6 ui-sm-12">
						<label for="first_name">{{'First Name'}}<span class="text-danger">*<span class="text-danger" *ngIf="(guessForm.controls['first_name'].invalid)&& guessForm.controls['first_name'].touched && (guessForm.controls['first_name'].hasError('required'))" style="font-size: 12px">{{'This field is required'}}</span></span></label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-user"></i>
								</button>
							</div>
							<input type="text" formControlName="first_name" [(ngModel)]="guess.firstName" placeholder="{{'John'}}" class="form-control w-100">
						</div>
					</div>
					<div class="ui-g-6 ui-sm-12">
						<label for="last_name">{{'Last Name'}}<span class="text-danger" *ngIf="(guessForm.controls['last_name'].invalid) && guessForm.controls['last_name'].touched && (guessForm.controls['last_name'].hasError('required'))" style="font-size: 12px">{{'This field is required'}}</span></label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-lock"></i>
								</button>
							</div>
							<input type="text" placeholder="{{'Doe'}}" formControlName="last_name" [(ngModel)]="guess.lastName" class="form-control w-100">
						</div>
					</div>
					<div class="ui-g-6 ui-sm-12">
						<label for="email">{{'Email'}} <span class="text-danger" *ngIf="guessForm.controls['email'].errors?.pattern && guessForm.controls['email'].dirty" style="font-size: 12px">{{'Required'}}</span></label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-at"></i>
								</button>
							</div>
							<input type="email" formControlName="email" [(ngModel)]="guess.email" placeholder="example@operr.com" class="form-control">
						</div>
					</div>
					<div class="ui-g-6 ui-sm-12">
						<label for="">{{'Username'}} (8 chars min)<span class="text-danger">*</span><span class="text-danger ms-2" *ngIf="(guessForm.controls['username'].invalid)&& guessForm.controls['username'].touched && (guessForm.controls['username'].hasError('required'))" style="font-size: 12px">{{'Required'}}</span></label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-user-shield"></i>
								</button>
							</div>
							<input type="text" formControlName="username" [(ngModel)]="guess.username" autocomplete="off" placeholder="{{'username'}}" (keydown.space)="$event.preventDefault()" class="form-control">
						</div>
						<span class="text-danger" *ngIf="guessForm.get('username').errors?.minLength" style="font-size: 12px">{{'validate.minlength' }}</span>
					</div>
					<div class="ui-g-6 ui-sm-12">
						<label for="">{{'Password'}}<span class="text-danger">*</span></label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-lock"></i>
								</button>
							</div>
							<input [type]="showPwd ? 'text' : 'password'" id="password" formControlName="password" [(ngModel)]="guess.password" name="password" placeholder="{{'Password'}}" class="form-control"/>
							<div class="input-group-append">
								<button type="button" class="btn btn-white" id="button-addon3" (click)="showPassword(); this.show = !this.show" style="cursor:pointer !important;">
									<i class="fa-solid" [class]="showPwd ? 'fa-eye-slash text-danger' : 'fa-eye text-primary'"></i>
								</button>
							</div>
						</div>
					</div>

					<div class="ui-g-6 ui-sm-12">
						<label for="phone_code">{{'Country Code'}}<span class="text-danger">*</span></label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-earth"></i>
								</button>
							</div>
							<select class="selection form-control" formControlName="phone_code">
								<option *ngFor="let item of listOfPhoneCode" [value]="item.value">{{item.label}}</option>
							</select>
						</div>
						<span class="float-end text-danger" *ngIf="guessForm.controls['phone_code'].invalid && guessForm.controls['phone_code'].touched && guessForm.controls['phone_code'].hasError('required')" style="font-size: 12px">{{'This field is required'}}<</span>
					</div>
					<div class="ui-g-6 ui-sm-12">
						<label for="">{{'Phone Number'}}<span class="text-danger">*</span></label>
						<div class="input-group w-100">
							<div class="input-group-prepend p-0">
								<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
									<i class="fa-solid fa-mobile-phone"></i>
								</button>
							</div>
							<input mask="(999)999-9999" formControlName="phone" [(ngModel)]="guess.phone" class="form-control w-100">
						</div>
						<span class="float-end text-danger" *ngIf="(guessForm.controls['phone'].invalid)&& guessForm.controls['phone'].touched && (guessForm.controls['phone'].hasError('required'))" style="font-size: 12px">{{'validate.required'}}</span>
					</div>

			
				<div class="ui-g-6 ui-sm-12" >
					<label for="">{{'Service Types'}}<span class="text-danger">*</span></label>
					<div class="input-group w-100">
						<div class="input-group-prepend p-0">
							<button class="btn btn-white" id="button-addon2" type="button" style="cursor:default !important">
								<i class="fa-solid fa-square-check"></i>
							</button>
						</div>
						<ng-multiselect-dropdown name="selectedServices" class="w-100" [attr.placeholder]="'Select service type'" [settings]="dropdownSettings" [data]="options" (onSelect)="onSelectionChange($event)" [(ngModel)]="selectedServices" formControlName="serviceTypes" id="serviceTypes">

						</ng-multiselect-dropdown>
					</div>
				</div>
			</div>
		</form>
	 </div>
	  <div class="ui-g">
		<div class="ui-g-12 text-center py-5">
		  <a class="btn btn-danger btn-lg mx-1" href="/home">
			<span><i class="fa-solid fa-xmark me-2"></i>Cancel</span>
		  </a>
		  <a class="btn btn-primary btn-lg mx-1" (onClick)="createGuess(guessDataForm)">
			<span><i class="fa-solid fa-check me-2"></i>Continue<i class="fa-solid fa-chevron-right ms-2"></i></span>
		  </a>
		</div>
	  </div>
	</div>
  </div>

  

<p-dialog [showHeader]="true" *ngIf="showPolicy" [(visible)]="showPolicy" modal="modal" [dismissableMask]="true" showEffect="fade" class="deparment-history" [style]="{'width':'80%'}">
	<p-header>
		<span><i class="fa-solid fa-info me-2"></i>Terms and Privacy Policy</span>
	</p-header>
	<h2>TERMS OF SERVICE / USER AGREEMENT</h2>
	<h3>
		Terms and Pronouns
	</h3>
	<div>
		The following terms shall be applicable throughout the entirety of this Agreement.
		Us, Our, Ours, We and Operr Work refer to Operr Work Inc. and Operr Work Technologies.
		You, Yours, Merchant(s), User(s), Buyer(s), Member(s) and Customer(s) refers to anyone legitimately using the
		Operr Work platform.
		The Mobile Application Terms of Use, all terms and additional policies published on and in our Services are
		incorporated into this User Agreement. Please read our Terms of Service so you understand what’s up with your
		use of Operr Work. You agree to our Terms of Service (“Terms”) by installing, accessing, or using our apps,
		Services, features, software, or website (Collectively, “Services”).
	</div>
	<ul>
		<li>Who you are?</li>
		<li>What matters to you.</li>
		<li>What you do.</li>
		<li>How you do it.</li>
	</ul>
	<h3>I. Overview</h3>
	<div>
		Our Operr Work Rules or The Terms of Use combine all documents/sections/rules that are listed below. The Operr
		Work Terms of Use (Operr Work Terms for short) is a legally binding contract between you and Operr Work. Once
		you have activities, Pay or participation under any means, you will be bound by Operr Work Terms.
		Operr Work Terms will outline your duties and your rights when you participate on Operr Work or use any Services
		that Operr Work provides. By participating on Operr Work under any means or using any of our Services, you are
		agreeing to these terms.
	</div>
	<div>
		In the event that you disagree with any of Our Terms contained herein, then you must cease using Operr Work.
	</div>
	<div>
		Please read the agreement carefully as our Operr Work Terms Section entitle Disputes, defines the way we, at
		Bynfor, handle any dispute you may have with Bynfor. In Section Disputes, by agreeing with Operr Work Terms and
		using Bynfor service, you agree to resolve all disputes through binding individual arbitration, which means that
		you agree to bring claims against Operr Work in your one and only individual capacity; you will waive any right
		to have those disputes decided by a judge or jury, which means you cannot bring claims against Operr Work as
		plaintiff or member of a class action, and for that, you agree to waive your right to participate in class
		actions, class arbitration, or representative action.
	</div>
</p-dialog>
